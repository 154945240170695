<template>
  <div class="modal" :class="{ 'is-active': shouldShowSummaryModal }">
    <div class="modal-background"></div>
    <div class="modal-card modal-width">
      <div class="modal-card-body modal-width">
        <div class="columns">
          <div class="column is-two-third" style="background: #fff">
            <div>
              <div class="columns">
                <div class="column">
                  <button
                    class="button cross tag is-pulled-left has-background-grey-lighter font-color-purple is-medium m-4"
                    @click="toggleShouldShowModal"
                  >
                    <i class="fas fa-times fa-2x py-12"></i>
                  </button>
                </div>
                <div class="column is-two-third">
                  <figure
                    class="image mt-4 mr-4"
                    style="width: 200px; margin: auto"
                  >
                    <img src="@/assets/logo.svg" />
                  </figure>
                </div>
              </div>
            </div>
            <div style="height: 350px; overflow-x: hidden">
              <div v-for="product in cart" v-bind:key="product.id">
                <div class="columns is-mobile is-vcentered">
                  <div class="column is-2 ml-2">
                    <figure
                      class="image p-2"
                      style="margin: auto; max-width: 100px"
                    >
                      <img
                        v-bind:src="`./icon/${product.icon}`"
                        alt="Placeholder image"
                      />
                    </figure>
                  </div>
                  <div class="column is-3">
                    <span class="font-color-purple mb-2">
                      <span class="is-size-4"> {{ product.name }} </span>
                    </span>
                  </div>
                  <div class="column is-1">
                    <button
                      class="button cross tag has-background-grey-lighter font-color-purple is-medium"
                      @click="removeProductFromCart(product)"
                    >
                      <i class="fas fa-minus fa-2x py-12"></i>
                    </button>
                  </div>
                  <div class="column is-2">
                    <span class="font-color-purple mb-2">
                      <span class="is-size-4"> x{{ product.quantity }} </span>
                    </span>
                  </div>
                  <div class="column is-1">
                    <button
                      class="button cross tag has-background-grey-lighter font-color-purple is-medium"
                      @click="addProductToCart(product)"
                    >
                      <i class="fas fa-plus fa-2x py-12"></i>
                    </button>
                  </div>
                  <div class="column is-3">
                    <button
                      class="button cross tag has-background-grey-lighter font-color-purple is-medium"
                      @click="deleteProductFromCart(product)"
                    >
                      <i class="fas fa-times fa-2x py-12"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <span class="is-size-5 font-color-purple">
                  IS CLIENTE DISPONE DI BUONI SCONTO
                </span>
                <span class="is-size-6 font-color-purple">
                  CHIDIAL CLIENTE SE INTENDE USARLI
                </span>
              </div>
              <button
                class="button selected-category discount-button-font-white"
                v-bind:class="{ 'grey-cat': getProductDiscount(7) == 20 }"
                v-if="presentInCart(7)"
                @click="applyProductDiscount(20, 7)"
              >
                20% on birra grande
              </button>
              <button
                class="button selected-category discount-button-font-white"
                v-bind:class="{ 'grey-cat': getProductDiscount(3) == 50 }"
                v-if="presentInCart(3)"
                @click="applyProductDiscount(50, 3)"
              >
                50% on pranzo
              </button>
              <br />
              <button
                class="button selected-category discount-button-font-white"
                v-bind:class="{ 'grey-cat': discount == 10 }"
                @click="applyDiscount(10)"
              >
                SCONTO 10% SUL TOTALE
              </button>
              <button
                class="button selected-category discount-button-font-white"
                v-bind:class="{ 'grey-cat': discount == 30 }"
                @click="applyDiscount(30)"
              >
                SCONTO 30% SUL TOTALE
              </button>
            </div>
          </div>
          <div class="column is-one-third blue-background">
            <div class="mt-6">
              <span class="font-color-white mb-2">
                <span class="is-size-4"> TOTALE CONTO: </span>
              </span>
              <br />
              <span class="font-color-white mb-2">
                <span class="is-size-4">
                  {{ total.toString().replace(".", ",") }}
                </span>
              </span>
              <br />
              <button
                class="button is-medium font-color-purple m-4"
                @click="modifyTotal()"
              >
                MODIFICA CIFRA
              </button>
              <br />
              <br />
              <span class="font-color-white mb-2">
                <span class="is-size-4"> TOTALE PUNTI: </span>
              </span>
              <br />
              <span class="font-color-white mb-2">
                <span class="is-size-4">
                  {{ Math.round(total * 10) }}
                </span>
              </span>
              <br />
              <div class="mt-6">
                <button
                  class="button is-medium selected-category font-color-white"
                  @click="finalButton"
                >
                  FINITO
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DiscountModal
      v-if="shouldShowDiscountModal"
      :should-show-discount-modal="shouldShowDiscountModal"
      :close-discount-modal="closeDiscountModal"
      :discount="localDiscount"
    />
    <ProductDiscountModal
      v-if="shouldShowProductDiscountModal"
      :data-product-discount="dataProductDiscount"
      :data-product-id="dataProductId"
      :should-show-product-discount-modal="shouldShowProductDiscountModal"
      :close-product-discount-modal="closeProductDiscountModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DiscountModal from "./DiscountModal";
import ProductDiscountModal from "./ProductDiscountModal";

export default {
  name: "Summary",
  props: [
    "shouldShowSummaryModal",
    "toggleShouldShowModal",
    "toggleTotalModal",
  ],
  data() {
    return {
      shouldShowDiscountModal: false,
      shouldShowProductDiscountModal: false,

      discountProductId: null,

      dataProductDiscount: 0,
      dataProductId: 0,
    };
  },
  components: { DiscountModal, ProductDiscountModal },
  computed: {
    ...mapGetters([
      "cart",
      "totalModified",
      "total",
      "discount",
      "discountedProducts",
      "discountedProduct",
      "productDiscount",
    ]),
  },
  watch: {
    cart() {
      this.calculateTotal();
    },
  },
  methods: {
    ...mapActions([
      "setAppliedDiscount",
      "addProductToCart",
      "removeProductFromCart",
      "deleteProductFromCart",
      "calculateTotal",
      "setProductDiscount",
    ]),
    presentInCart(id) {
      const productIndex = this.cart.findIndex((prod) => prod.id == id);
      if (productIndex == -1) {
        return false;
      }
      return true;
    },
    modifyTotal() {
      this.toggleTotalModal();
    },
    finalButton() {
      // this.setCart({});
    },
    applyDiscount(discount) {
      if (this.discount == discount) {
        this.setAppliedDiscount(0);
        return;
      }
      this.localDiscount = discount;
      this.shouldShowDiscountModal = true;
    },
    applyProductDiscount(discount, productId) {
      const product = this.cart.find((prod) => prod.id == productId);
      if (product.discount && discount == product.discount) {
        this.setProductDiscount({
          product,
          discount: 0,
        });
        return;
      }
      this.dataProductDiscount = Number(discount);
      this.dataProductId = Number(productId);
      this.shouldShowProductDiscountModal = true;
    },
    closeDiscountModal() {
      this.shouldShowDiscountModal = false;
    },
    closeProductDiscountModal() {
      this.shouldShowProductDiscountModal = false;
    },
    getProductDiscount(id) {
      const prod = this.cart.find((prod) => prod.id == id);
      return prod.discount;
    },
  },
};
</script>

<style scoped>
.font-color-purple {
  color: #573d97;
  font-weight: 900;
}
.font-color-white {
  color: #fff;
  font-weight: 900;
}
.discount-button-font-purple {
  color: #573d97;
  font-weight: 400;
}
.discount-button-font-white {
  color: #fff;
  font-weight: 400;
}
.cross {
  height: 2.5rem;
  width: 2.5rem;
}
.selected-category {
  background: #fbb657;
}
.blue-background {
  background: #573d97;
}
@media (min-width: 768px) {
  .modal-width {
    width: 800px;
  }
}
.grey-cat {
  opacity: 0.5;
}
</style>