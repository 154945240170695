<template>
  <div class="modal" :class="{ 'is-active': shouldShowProductDiscountModal }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body blue-background">
        <p class="is-size-5 font-color-white m-2">Enter product id ?</p>
        <p class="font-color-orange is-size-4 m-2">
          SCONTO {{ dataProductDiscount }}% <br />
          SUL TOTALE
        </p>
        <div>
          <button
            class="button white-background font-color-purple m-2 button-width"
            @click="closeProductDiscountModal()"
          >
            RIFIUTA
          </button>
          <button
            class="button selected-category font-color-white m-2 button-width"
            @click="applyProductDiscount()"
          >
            CONFERMA
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ProductDiscountModal",
  computed: mapGetters(["cart"]),
  data() {
    return {
      productId: null,
    };
  },
  props: [
    "shouldShowProductDiscountModal",
    "productDiscount",
    "closeProductDiscountModal",
    "dataProductId",
    "dataProductDiscount",
  ],
  methods: {
    ...mapActions(["setProductDiscount"]),
    applyProductDiscount() {
      this.dataProductId = Number(this.dataProductId);
      this.dataProductDiscount = Number(this.dataProductDiscount);
      if (this.dataProductId) {
        const product = this.cart.find((prod) => prod.id == this.dataProductId);
        if (product) {
          this.setProductDiscount({
            product,
            discount: this.dataProductDiscount,
          });
          this.closeProductDiscountModal();
        } else {
          console.log("productNot");
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
.button-width {
  width: 200px;
}
.selected-category {
  background: #fbb657;
}
.blue-background {
  background: #573d97;
}
.white-background {
  background: #fff;
}
.font-color-purple {
  color: #573d97;
  font-weight: 900;
}
.font-color-orange {
  color: #fbb657;
  font-weight: 900;
}
.font-color-white {
  color: #fff;
  font-weight: 400;
}
.modal-card-body {
  border-radius: 12px;
}
</style>