import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    categories: [
      { id: 1, name: "CAFFETERIA", icon: "capuccino.svg" },
      { id: 2, name: "APERITIVO", icon: "aperitivo.svg" },
      { id: 3, name: "COCKTAIL", icon: "cocktail.svg" },
      { id: 4, name: "ALCOLICO", icon: "alcolico.svg" },
      { id: 5, name: "EXTRA", icon: "pranzo.svg" },
    ],
    products: [
      { id: 1, category: 1, price: 3, name: "prosecco", icon: "prosecco.svg" },
      {
        id: 2,
        category: 1,
        price: 1,
        name: "vino",
        icon: "vino-bicchiere.svg",
      },
      { id: 3, category: 1, price: 10, name: "pranzo", icon: "pranzo.svg" },
      { id: 4, category: 1, price: 5, name: "cocktail", icon: "cocktail.svg" },
      {
        id: 5,
        category: 1,
        price: 15,
        name: "caraffa",
        icon: "caraffa_limonata.svg",
      },
      {
        id: 6,
        category: 2,
        price: 1,
        name: "capuccino",
        icon: "capuccino.svg",
      },
      {
        id: 7,

        category: 2,
        price: 5,
        name: "birra grande",
        icon: "birragrande.svg",
      },
      { id: 8, category: 3, price: 3, name: "Birra", icon: "birra.svg" },
      {
        id: 9,
        category: 4,
        price: 3,
        name: "Aperitivo",
        icon: "aperitivo.svg",
      },
      {
        id: 10,
        category: 5,
        price: 5,
        name: "Americano",
        icon: "americano.svg",
      },
      {
        id: 11,
        category: 5,
        price: 5,
        name: "Alcolico",
        icon: "alcholico.svg",
      },
      { id: 12, category: 5, price: 5, name: "Piadina", icon: "pranzo.svg" },
    ],
    selectedCategory: null,
    categoryProducts: [],
    total: 0,
    cart: [],
    totalModified: false,
    discount: 0,
  },
  mutations: {
    SET_CATEGORY_PRODUCTS: (state, products) =>
      (state.categoryProducts = products),
    SET_CART: (state, cart) => (state.cart = cart),
    SET_SELECTED_CATEGORY: (state, category) =>
      (state.selectedCategory = category),
    SET_TOTAL: (state, total) => (state.total = total),
    SET_MODIFIED: (state, isModified) => (state.totalModified = isModified),
    SET_DISCOUNT: (state, discount) => (state.discount = discount),
  },
  actions: {
    selectCategory(context, category) {
      if (
        this.state.selectedCategory &&
        this.state.selectedCategory.id === category.id
      ) {
        context.commit("SET_SELECTED_CATEGORY", null);
        context.commit("SET_CATEGORY_PRODUCTS", []);
        return;
      }
      const categoryProducts = context.state.products.filter(
        (product) => product.category === category.id
      );
      context.commit("SET_SELECTED_CATEGORY", category);
      context.commit("SET_CATEGORY_PRODUCTS", categoryProducts);
    },
    setCart(context, cart) {
      context.commit("SET_CART", cart);
    },
    calculateTotal(context) {
      let totalAmount = 0;
      const cart = this.getters.cart;
      cart.forEach((product) => {
        if (!product.discount) {
          product.discount = 0;
        }
        const priorTotal = product.price * product.quantity;
        totalAmount = totalAmount + priorTotal;

        totalAmount =
          totalAmount -
          Math.round(product.price * product.quantity * product.discount) / 100;
      });

      const discount = this.getters.discount;
      if (discount) {
        totalAmount = totalAmount - Math.round(totalAmount * discount) / 100;
      }
      totalAmount = Number(totalAmount.toFixed(2));
      // const discount = this.getters.discount;
      // if (discount) {
      //   totalAmount = totalAmount - Math.round(totalAmount * discount) / 100;
      // }
      totalAmount = Number(totalAmount.toFixed(2));

      context.dispatch("setModified", false);
      context.commit("SET_TOTAL", totalAmount);
    },
    setTotal(context, total) {
      context.commit("SET_TOTAL", total);
    },
    setModified(context, isModified) {
      context.commit("SET_MODIFIED", isModified);
    },
    addProductToCart(context, product) {
      let cart = this.getters.cart;
      const productIndex = cart.findIndex((prod) => prod.id == product.id);
      if (productIndex == -1) {
        product.discount = 0;
        product["quantity"] = 1;
        cart.unshift(product);
      } else {
        product.quantity += 1;
        Vue.set(cart, productIndex, product);
      }
      context.dispatch("calculateTotal");
      context.commit("SET_CART", cart);
    },
    removeProductFromCart(context, product) {
      let cart = this.getters.cart;
      const productIndex = cart.findIndex((prod) => prod.id == product.id);
      if (product.quantity == 1) {
        Vue.delete(cart, productIndex);
      } else {
        product.quantity -= 1;
        Vue.set(cart, productIndex, product);
      }
      context.dispatch("calculateTotal");

      context.commit("SET_CART", cart);
      // context.commit("SET_DISCOUNT", 0);
    },
    deleteProductFromCart(context, product) {
      let cart = this.getters.cart;
      const productIndex = cart.findIndex((prod) => prod.id == product.id);
      Vue.delete(cart, productIndex);

      context.dispatch("calculateTotal");
      context.commit("SET_CART", cart);
      // context.commit("SET_DISCOUNT", 0);
    },
    setAppliedDiscount(context, discount) {
      let totalAmount = this.getters.total;

      if (this.getters.totalModified) {
        // let currentDiscount = this.getters.discount;
        // if (currentDiscount) {
        //   totalAmount = (totalAmount * 100) / (100 - currentDiscount);
        // }
        // if (discount) {
        //   totalAmount = totalAmount - Math.round(totalAmount * discount) / 100;
        // }
        // totalAmount = Number(totalAmount.toFixed(2));
      }
      context.dispatch("clearProductDiscount");
      context.commit("SET_DISCOUNT", discount);
      context.dispatch("calculateTotal");
      totalAmount = this.getters.total;

      context.commit("SET_DISCOUNT", discount);
      context.commit("SET_TOTAL", totalAmount);
    },
    setProductDiscount(context, payload) {
      let cart = this.getters.cart;
      const productIndex = cart.findIndex(
        (prod) => prod.id == payload.product.id
      );
      const product = payload.product;
      product.discount = payload.discount;
      Vue.set(cart, productIndex, product);

      context.commit("SET_DISCOUNT", 0);
      context.commit("SET_CART", cart);
    },
    clearProductDiscount(context) {
      let cart = this.getters.cart;
      cart.forEach((product) => {
        product.discount = 0;
      });
      context.commit("SET_CART", cart);
      context.dispatch("calculateTotal");
    },
    clearDiscount(context) {
      context.commit("SET_DISCOUNT", 0);
    },
    // setDiscountedProduct(context, productId) {
    // context.commit("SET_DISCOUNTED_PRODUCT", productId)
    // },
    // setProductDiscount(context, productDiscount) {
    //   context.commit("SET_PRODUCT_DISCOUNT", productDiscount)
    // }
  },
  getters: {
    categories: (state) => state.categories,
    categoryProducts: (state) => state.categoryProducts,
    products: (state) => state.products,
    cart: (state) => state.cart,
    selectedCategory: (state) => state.selectedCategory,
    total: (state) => state.total,
    totalModified: (state) => state.totalModified,
    discount: (state) => state.discount,
  },
});
