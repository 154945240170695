<template>
  <div>
    <div class="has-background-grey-lighter" style="padding: 30px">
      <figure class="image" style="width: 190px; margin: auto; padding: 20px">
        <img src="@/assets/logo.svg" />
      </figure>
      <div class="columns">
        <div
          v-for="category in categories"
          v-bind:key="category.id"
          class="column is-one-fifth"
        >
          <div
            @click="selectCategory(category)"
            class="card"
            v-bind:class="{
              'grey-cat':
                selectedCategory && category.id !== selectedCategory.id,
              'selected-category':
                selectedCategory && category.id === selectedCategory.id,
            }"
          >
            <div class="card-image">
              <figure class="image" style="width: 50px">
                <img
                  v-bind:src="`./icon/${category.icon}`"
                  alt="Placeholder image"
                />
              </figure>
              <span
                class="font-color-purple"
                :class="{
                  'font-color-white':
                    selectedCategory && category.id === selectedCategory.id,
                }"
              >
                {{ category.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div
          v-for="product in categoryProducts"
          v-bind:key="product.id"
          class="column is-one-fifth"
        >
          <div class="card" @click="addProductToCart(product)">
            <div class="card-image m-12">
              <figure class="image" style="width: 50px">
                <img :src="`./icon/${product.icon}`" alt="Placeholder image" />
              </figure>
              <span class="font-color-purple">
                {{ product.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="m-5"
      style="
        height: 70px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        display: flex;
        align-content: left;
      "
    >
      <div
        v-for="product in cart"
        v-bind:key="product.id"
        class="tags are-large display mb-2 ml-5"
      >
        <button
          class="button cross tag has-background-grey-lighter font-color-purple is-medium"
          @click="deleteProductFromCart(product)"
        >
          <i class="fas fa-times fa-2x py-12"></i>
        </button>
        <span class="font-color-purple tag has-background-grey-lighter mb-2">
          <span class="is-size-4"> {{ product.name }} </span>
        </span>
        <span class="is-size-4 font-color-purple">
          x{{ product.quantity }}
        </span>
      </div>
    </div>
    <button
      class="button is-large selected-category font-color-white m-4"
      style="width: 200px"
      @click="fullScreen()"
    >
      fullScreen
    </button>
    <button
      class="button is-large selected-category font-color-white m-4 is-pulled-right"
      style="width: 200px"
      @click="gotoSummary()"
    >
      FATTO
    </button>
    <Summary
      v-if="shouldShowSummaryModal"
      :should-show-summary-modal="shouldShowSummaryModal"
      :toggle-should-show-modal="toggleShouldShowModal"
      :toggle-total-modal="toggleTotalModal"
    />
    <EditTotal
      v-if="shouldShowEditTotalModal"
      :should-show-edit-total-modal="shouldShowEditTotalModal"
      :toggle-total-modal="toggleTotalModal"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import Summary from "./Summary";
import EditTotal from "./EditTotal";

export default {
  name: "Home",
  data() {
    return {
      shouldShowSummaryModal: false,
      shouldShowEditTotalModal: false,
      localCart: [],
    };
  },
  components: { Summary, EditTotal },
  computed: mapGetters([
    "categories",
    "products",
    "categoryProducts",
    "selectedCategory",
    "cart",
  ]),

  methods: {
    ...mapActions([
      "selectCategory",
      "setModified",
      "addProductToCart",
      "deleteProductFromCart",
      "calculateTotal",
      "clearDiscount",
    ]),
    toggleShouldShowModal() {
      this.shouldShowSummaryModal = !this.shouldShowSummaryModal;
    },
    toggleTotalModal() {
      this.shouldShowEditTotalModal = !this.shouldShowEditTotalModal;
    },
    gotoSummary() {
      this.clearDiscount();
      this.calculateTotal();
      this.shouldShowSummaryModal = true;
    },
    fullScreen() {
      document.documentElement.webkitRequestFullScreen();
    },
  },
};
</script>

<style scoped>
.card {
  height: 120px;
  border-radius: 0.5rem;
  padding: 24px 20px;
}
.card-image .image {
  margin: 0 auto;
  margin-bottom: 10px;
}
.card-image span {
  font-size: 16px;
  text-transform: uppercase;
}
.font-color-purple {
  color: #573d97;
  font-weight: 900;
}
.font-color-white {
  color: #fff;
  font-weight: 900;
}
.selected-category {
  background: #fbb657;
}
.display {
  display: inline-block;
}
.cross {
  height: 2.5rem;
  width: 2.5rem;
}
.grey-cat {
  opacity: 0.5;
}
</style>