<template>
  <div class="modal" :class="{ 'is-active': shouldShowEditTotalModal }">
    <div class="modal-background"></div>
    <div class="modal-card modal-width">
      <div class="modal-card-body modal-width" style="background: #573d97">
        <div class="columns" style="height: 100px">
          <div class="column">
            <button
              class="button cross tag is-pulled-left has-background-white font-color-purple is-medium m-4"
              @click="toggleTotalModal"
            >
              <i class="fas fa-times fa-2x py-12"></i>
            </button>
          </div>
          <div class="column is-half">
            <figure class="image m-4" style="width: 200px; margin: auto">
              <img src="@/assets/logo-white.svg" />
            </figure>
          </div>
        </div>
        <div class="columns" style="height: 450px">
          <div class="column is-5">
            <div>
              <span class="font-color-white is-size-3 my-2">
                CONTO PRECEDENTE
              </span>
              <br />
              <span class="font-color-white is-size-2 mb-2">
                {{ total }} €
              </span>
            </div>
            <div class="custom-tag mt-3">
              <div class="is-size-3 font-color-purple pt-2">CONTO ATTUALE</div>
              <div>
                <span class="is-size-2 font-color-purple mb-2">
                  {{ localTotal.toString().replace(".", ",") }} €
                </span>
              </div>
            </div>
            <div class="mt-3">
              <button
                class="button is-medium selected-category font-color-white is-size-2 p-0"
                style="width: 300px; height: 60px"
                @click="changeTotal"
              >
                FINITO
              </button>
            </div>
          </div>
          <div class="column is-7">
            <span class="font-color-white is-size-4 mb-2">
              INSERISCI LA CIFRA CHE DESIDERI
            </span>
            <div>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(7)"
              >
                7
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(8)"
              >
                8
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(9)"
              >
                9
              </button>
            </div>
            <div>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(4)"
              >
                4
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(5)"
              >
                5
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(6)"
              >
                6
              </button>
            </div>
            <div>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(1)"
              >
                1
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(2)"
              >
                2
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(3)"
              >
                3
              </button>
            </div>
            <div>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress(0)"
              >
                0
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="digitPress('.')"
              >
                <span class="mb-4">,</span>
              </button>
              <button
                class="button digit tag is-size-1 has-background-white font-color-purple is-large m-1"
                @click="cPress"
              >
                C
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditTotal",
  props: ["shouldShowEditTotalModal", "toggleTotalModal"],
  data() {
    return {
      localTotal: "_ _",
    };
  },
  computed: {
    ...mapGetters(["total"]),
  },
  methods: {
    ...mapActions(["setModified", "setTotal", "setAppliedDiscount"]),
    digitPress(digit) {
      if (isNaN(this.localTotal)) {
        this.localTotal = "";
      }
      if (this.localTotal.match(/^\d*(\.)?(\d{0,1})?$/)) {
        this.localTotal = this.localTotal + digit;
      }
    },
    changeTotal() {
      if (this.localTotal.length && Number(this.localTotal)) {
        this.localTotal = Number(this.localTotal);
        // this.setAppliedDiscount(0);
        this.setModified(true);
        this.setTotal(this.localTotal);
      }
      this.toggleTotalModal();
    },
    cPress() {
      this.localTotal = this.localTotal.slice(0, -1);
    },
  },
};
</script>

<style scoped>
.font-color-purple {
  color: #573d97;
  font-weight: 900;
}
.font-color-white {
  color: #fff;
  font-weight: 900;
}
.digit {
  height: 5rem;
  width: 8rem;
}
.selected-category {
  background: #fbb657;
}
.cross {
  height: 2.5rem;
  width: 2.5rem;
}
.custom-tag {
  margin: auto;
  height: 150px;
  background: #fff;
  width: 300px;
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .modal-width {
    width: 900px;
  }
}
</style>