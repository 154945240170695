<template>
  <div class="modal" :class="{ 'is-active': shouldShowDiscountModal }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body blue-background">
        <p class="is-size-5 font-color-white m-2">
          CONFERMI DI ATTIVARE IL SEGUENTE <br />
          BUONO PER IL CLIENTE ?
        </p>
        <p class="font-color-orange is-size-4 m-2">
          SCONTO {{ discount }}% <br />
          SUL TOTALE
        </p>
        <div>
          <button
            class="button white-background font-color-purple m-2 button-width"
            @click="closeDiscountModal()"
          >
            RIFIUTA
          </button>
          <button
            class="button selected-category font-color-white m-2 button-width"
            @click="applyDiscount()"
          >
            CONFERMA
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "DiscountModal",
  props: ["shouldShowDiscountModal", "discount", "closeDiscountModal"],
  methods: {
    ...mapActions(["setAppliedDiscount"]),
    applyDiscount() {
      this.setAppliedDiscount(this.discount);
      this.closeDiscountModal();
    },
  },
};
</script>

<style scoped>
.button-width {
  width: 200px;
}
.selected-category {
  background: #fbb657;
}
.blue-background {
  background: #573d97;
}
.white-background {
  background: #fff;
}
.font-color-purple {
  color: #573d97;
  font-weight: 900;
}
.font-color-orange {
  color: #fbb657;
  font-weight: 900;
}
.font-color-white {
  color: #fff;
  font-weight: 400;
}
.modal-card-body {
  border-radius: 12px;
}
</style>